import React from "react"
import Layout from "../layout/layout"
import usePosts from "../hooks/usePosts"
import PostPreview from "../components/PostPreview"
import config from "../../data/SiteConfig"
import SEO from "../components/SEO"
import { Helmet } from "react-helmet"

const ArticlesPage = () => {
	const posts = usePosts()
	return (
		<Layout>
			<Helmet>
				<title>{`Articles - ${config.siteTitle}`}</title>
				<SEO description="Follow me on my learning journey in the wild web! I blog about web dev, seo and game gamedesign!" />
			</Helmet>
			<h1>My Articles</h1>
			<article>
				{posts.map((post) => (
					<PostPreview post={post} key={post.title} />
				))}
			</article>
		</Layout>
	)
}

export default ArticlesPage
